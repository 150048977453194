import React from 'react'

export const isPresent = (data: any) => (data ? true : false);
export const comparison = (data: any, other: any) => data === other;
export const email = (data: any) =>
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    data
  );
export const password = (data: any) => /^(?=.*\d).{6,20}$/.test(data);
export const mobile = (data: any) => /^[1-9]{1}[0-9]{9}$/.test(data);
export const number = (data: any) => /^([0-9\-\(\)\s]+).{0,50}$/.test(data);
export const date = (data: any) => /^(\d{1,2})(\/)(\d{1,2})(\/)(\d{4})$/.test(data);
export const pincode = (data: any) => /^().{5,100}$/.test(data);


export function userRegisterValidate(values: any) {
  var errors: { [k: string]: any } = {};
  if (!values?.name) {
    errors.name = "Name is required";
  } else if (values?.name?.length < 3) {
    errors.name = "First name must be at least 3 characters";
  }
  // if (!values?.email) {
  //   errors.email = "Email is required";
  // } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
  //   errors.email = "Invalid email address";
  // }
  if (values?.email) {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Invalid email address";
    }
  }
  if (!values?.mobile) {
    errors.mobile = "Mobile no. is required";
  } else if (values?.mobile.length < 10 || values?.mobile.length > 30) {
    errors.mobile = "Invalid mobile number ";
  }
  return errors;
}

export function counsellorRegisterValidate(values: any) {
  var errors: { [k: string]: any } = {};
  if (!values?.name) {
    errors.name = "Name is required";
  } else if (values?.name?.length < 3) {
    errors.name = "First name must be at least 3 characters";
  }
  // if (!values?.email) {
  //   errors.email = "Email is required";
  // } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
  //   errors.email = "Invalid email address";
  // }
  if (values?.email) {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Invalid email address";
    }
  }
  if (!values?.mobile) {
    errors.mobile = "Mobile no. required";
  } else if (values?.mobile.length < 10 || values?.mobile.length > 30) {
    errors.mobile = "Invalid mobile number ";
  }
  if (!values?.roles) {
    errors.roles = "Select required";
  }
  if (!values?.linkedin) {
    errors.linkedin = "linkedin url is required";
  } else if (values?.linkedin.length < 6) {
    errors.linkedin = "Invalid linkedin url";
  }
  return errors;
}

export function userLoginValidate(values: any) {
  var errors: { [k: string]: any } = {};
  if (!values?.mobile) {
    errors.mobile = "Mobile no. is required";
  } else if (values?.mobile.length < 10 || values?.mobile.length > 30) {
    errors.mobile = "Invalid mobile number ";
  }
  if (!values?.password) {
    errors.password = "Password is required";
  } else if (values?.password?.length < 6) {
    errors.password = "Password must be at least 6 characters";
  }
  return errors;
}


export function otpValidate(values: any) {
  var errors: { [k: string]: any } = {};
  if (!values?.otp) {
    errors.otp = "Otp is required";
  } else if (values?.otp?.length < 6 || values?.length > 30) {
    errors.otp = "Invalid otp ";
  }
  return errors;
}


export function counselloerLoginValidate(values: any) {
  var errors: { [k: string]: any } = {};
  if (!values?.mobile) {
    errors.mobile = "Mobile no. is required";
  } else if (values?.mobile.length < 10 || values?.mobile.length > 30) {
    errors.mobile = "Invalid mobile number ";
  }
  return errors;
}

export function smartSearchValidation(values: any) {
  var errors: { [k: string]: any } = {};
  if (!values?.country) {
    errors.name = "Please select country";
  }
  return errors;
}

export function validateExamForm(values:any) {
    var errors: { [k: string]: any } = {};
    /* if (!values?.name) {
        errors.name = "Name Required";
    }
    if (!values?.mobile) {
        errors.mobile = "Mobile Required";
    } else if (!mobile(values.mobile?.trim())) {
        errors.mobile = "Invalid Mobile number";
    }
    if (!values?.email) {
        errors.email = "Email Required";
    } else if (!email(values.email)) {
        errors.email = "Invalid email address";
    } */
    /* if (!values?.intake) {
        errors.intake = "Intake Required";
    }
    if (!values?.country) {
        errors.country = "Country Required";
    }
    if (!values?.message) {
        errors.message = "Message Required";
    } else if (values?.message?.trim()?.length < 10 ) {
        errors.message = "Min 10 character required";
    } */
    return errors;
}
export function validateCoachForm(values:any) {
    var errors: { [k: string]: any } = {};
    /* if (!values?.name) {
        errors.name = "Name Required";
    }
    if (!values?.mobile) {
        errors.mobile = "Mobile Required";
    } else if (!mobile(values.mobile?.trim())) {
        errors.mobile = "Invalid Mobile number";
    }
    if (!values?.email) {
        errors.email = "Email Required";
    } else if (!email(values.email)) {
        errors.email = "Invalid email address";
    } */
    if (!values?.city) {
        errors.city = "City Required";
    }
    /* if (!values?.program) {
        errors.program = "Program Required";
    }
    if (!values?.areas) {
        errors.areas = "Areas Required";
    }
    if (!values?.interest) {
        errors.interest = "Interest Required";
    }
    if (!values?.training) {
        errors.training = "Training Required";
    } else if (values?.training?.trim()?.length < 10 ) {
        errors.training = "Min 10 character required";
    }
    if (!values?.about) {
        errors.about = "About Required";
    } else if (values?.about?.trim()?.length < 10 ) {
        errors.about = "Min 10 character required";
    } */
    return errors;
}
export function validateScholarForm(values:any) {
    var errors: { [k: string]: any } = {};
    /* if (!values?.name) {
        errors.name = "Name Required";
    }
    if (!values?.mobile) {
        errors.mobile = "Mobile Required";
    } else if (!mobile(values.mobile?.trim())) {
        errors.mobile = "Invalid Mobile number";
    }
    if (!values?.email) {
        errors.email = "Email Required";
    } else if (!email(values.email)) {
        errors.email = "Invalid email address";
    } */
    if (!values?.city) {
        errors.city = "City Required";
    }
    /* if (!values?.intake) {
        errors.intake = "Intake Required";
    }
    if (!values?.country) {
        errors.country = "Country Required";
    }
    if (!values?.message) {
        errors.message = "Message Required";
    } else if (values?.message?.trim()?.length < 10 ) {
        errors.message = "Min 10 character required";
    } */
    return errors;
}
export function validateContactUsForm(values:any) {
    var errors: { [k: string]: any } = {};
    if (!values?.name) {
        errors.name = "Name Required";
    }
    if (!values?.mobile) {
        errors.mobile = "Mobile Required";
    } else if (!mobile(values.mobile?.trim())) {
        errors.mobile = "Invalid Mobile number";
    }
    if (!values?.email) {
        errors.email = "Email Required";
    } else if (!email(values.email)) {
        errors.email = "Invalid email address";
    }
    /* if (!values?.message) {
        errors.message = "Message Required";
    } else if (values?.message?.trim()?.length < 10 ) {
        errors.message = "Min 10 character required";
    } */
    return errors;
}
export function validateInternShipForm(values:any) {
    var errors: { [k: string]: any } = {};
    /* if (!values?.name) {
        errors.name = "Name Required";
    }
    if (!values?.mobile) {
        errors.mobile = "Mobile Required";
    } else if (!mobile(values.mobile?.trim())) {
        errors.mobile = "Invalid Mobile number";
    }
    if (!values?.email) {
        errors.email = "Email Required";
    } else if (!email(values.email)) {
        errors.email = "Invalid email address";
    } */
    if (!values?.city) {
        errors.city = "City Required";
    }
    /* if (!values?.intake) {
        errors.intake = "Intake Required";
    }
    if (!values?.country) {
        errors.country = "Country Required";
    }
    if (!values?.file) {
        errors.file = "File Required";
    }
    if (!values?.message) {
        errors.message = "Message Required";
    } else if (values?.message?.trim()?.length < 10 ) {
        errors.message = "Min 10 character required";
    } */
    return errors;
}
export function validateFundingForm(values:any) {
    var errors: { [k: string]: any } = {};
    /* if (!values?.name) {
        errors.name = "Name Required";
    }
    if (!values?.mobile) {
        errors.mobile = "Mobile Required";
    } else if (!mobile(values.mobile?.trim())) {
        errors.mobile = "Invalid Mobile number";
    }
    if (!values?.email) {
        errors.email = "Email Required";
    } else if (!email(values.email)) {
        errors.email = "Invalid email address";
    } */
    if (!values?.city) {
        errors.city = "City Required";
    }
    return errors;
}