import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { blogAction, UniversityAction } from "../../redux/common/action";
import AdSlider from "../AdSlider/AdSlider";
import dateFormat from "dateformat";
import { getToken } from "../../Utils/Auth/Token";
import { URL } from "../../redux/common/url";
import jwt_decode from "jwt-decode";
import Seo from "../Seo/Seo";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
function Blog() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [topics, settopics] = useState<any[]>([]);
  const [topicsid, settopicsid] = useState<any>("");
  const [like, setlike] = useState<any>("");
  const [loadmore, setloadmore] = useState<any>(10);
  const [offset, setoffset] = useState<any>();
  const [comment, setcomment] = useState<any>("");
  const [title, settitle] = useState<any>("blog");
  const [blogSearching, setblogSearching] = useState<any>("");
  const [viewAll, setviewAll] = useState<any>(9);
  const isAuthenticated: any = getToken();
  const baseurl = URL.API_BASE_URL;

  var user: any = isAuthenticated && jwt_decode(isAuthenticated);
  const blogLikeUnlike: any = useSelector((state: any) =>
    state.blog.blogLikeUnlike ? state.blog.blogLikeUnlike : []
  );





  const blogList: any = useSelector((state: any) =>
    state.blog.blogList ? state.blog.blogList : []
  );

  const fitBlog =
    blogList &&
    blogList?.filter((data: any) => {
      return data?.status == 1;
    });

  const filterBlog =
    fitBlog &&
    fitBlog?.filter((data: any) => {
      return data?.BlogCategory?.status == 1; 
    });

    console.log('fitBlog');
    console.log(fitBlog);
  const blogIds: any = localStorage.getItem("blog_id");
  const arr = JSON.parse(blogIds && blogIds);
  const reverseArray = arr && Array.prototype.reverse.call(arr);
  // var newArray: any = [];
  // var newArray =
  //   reverseArray &&
  //   reverseArray.filter(function (elem: any, pos: any) {
  //     return reverseArray.indexOf(elem) == pos;
  //   });

  const keys = ["id"]
  const filtered =
    reverseArray &&
    reverseArray?.filter(
      (
        (s) => (o: any) =>
          ((k) => !s.has(k) && s.add(k))(keys.map((k: any) => o[k]).join("|"))
      )(new Set())
    );


  console.log(filtered);



  const blogCategory: any[] = useSelector((state: any) =>
    state.blog.blogCategory ? state.blog.blogCategory : []
  );

  const blogCat =
    blogCategory &&
    blogCategory?.filter((data: any) => {
      return data.status == 1;
    });

  const blogSearch = (e: any) => {
    const headingName = e.target.value ? { heading: e.target.value } : "";
    dispatch(blogAction.getbloglist(headingName));
  };
  useEffect(() => {
    dispatch(UniversityAction.getAdBanner({status: 1}));
    return () => {}
  }, [])
  useEffect(() => {
    dispatch(blogAction.getbloglist({ limit: 10, offset: 0 }));
    return () => { };
  }, [blogLikeUnlike]);

  useEffect(() => {
    dispatch(blogAction.getBlogCategory({}));
    return () => { };
  }, []);

  const hendleLike = (e: any, data: any) => {
    if (isAuthenticated == false) {
      history.push("/login", { logback: 1 });
    } else {
      var user: any = isAuthenticated && jwt_decode(isAuthenticated);
      dispatch(
        blogAction.blogLikeUnlike({ blog_id: data, student_id: user?.id })
      );
    }
  };
  const hendlecomment = (e: any) => {
    if (isAuthenticated == false) {
      history.push("/login");
    } else {
      if (comment == true) {
        setcomment(false);
      } else {
        setcomment(e);
      }
    }
  };

  const hendleLodemore = (e: any) => {
    setloadmore(loadmore + 10)
    dispatch(blogAction.getbloglist({ limit: loadmore + 10, offset: 0 }));
    return () => { };

  }


  const hendlClicktopics = (event: any) => {
    let array = [...topics];
    let arrayid = [...topicsid];
    setblogSearching("")
    if (event.target.checked) {
      arrayid.push(event.target.name);
      array.push(event.target.value);
      settopics(array);
      settopicsid(arrayid);
      const catagoryId = arrayid?.length == 0 ? "" : { category_id: arrayid };
      dispatch(blogAction.getbloglist(catagoryId));
      return () => { };
    } else {
      let coun = arrayid.indexOf(event.target.name);
      if (coun > -1) {
        arrayid.splice(coun, 1);
        array.splice(coun, 1);
        settopicsid(arrayid);
        settopics(array);
        const catagoryId = arrayid?.length == 0 ? "" : { category_id: arrayid };
        dispatch(blogAction.getbloglist(catagoryId));
        return () => { };
      }
    }
  };

  const remove = (event: any, value: any) => {
    let array = [...topics];
    let arrayid = [...topicsid];
    let coun = array.indexOf(value);
    if (coun > -1) {
      array.splice(coun, 1);
      arrayid.splice(coun, 1);
      settopics(array);
      settopicsid(arrayid);

      const catagoryId = arrayid?.length == 0 ? "" : { category_id: arrayid };
      dispatch(blogAction.getbloglist(catagoryId));
      return () => { };
    }
  };

  return (
    <div>
      <Seo slug={window.location.pathname}/>
      <section className="unifilter-page unifilter__mob">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 order-lg-2">
              <div className="page-sidebar">
                <div className="widget sidebar-title">
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control blogsearch"
                      placeholder="Search heading"
                      value={blogSearching}
                      onChange={(e) => {
                        blogSearch(e)
                        setblogSearching(e.target.value)
                      }}
                    />
                  </div>
                </div>

                {/* <div className="widget sidebar-title">
                  <h5>
                    <span>
                      selected <b>filters</b> <i className="fa fa-angle-up"></i>
                    </span>{" "}
                  </h5>
                  <ul className="clear-filter">
                    {topics.map((data: any, i: any) => {
                      return (
                        <li key={i}>
                          {data}
                          <a onClick={(e: any) => remove(e, data)}>
                            <sup>
                              <i className="fa fa-close"></i>
                            </sup>
                          </a>
                        </li>
                      );
                    })}
                   <li>news<a href="#"><sup><i className="fa fa-close"></i></sup></a></li> 
                  </ul>
                </div> */}
                {/* <!-- WIDGET --> */}
                <div className="widget widget_has_radio_checkbox">
                  <h5>
                    <span>category</span>{" "}
                  </h5>
                  <ul>
                    {blogCat &&
                      blogCat?.map((data: any, i: any) => {
                        return (
                          <li key={i}>
                            <label>
                              <input
                                name={data?.id}
                                type="checkbox"
                                value={data?.category}
                                checked={topics.includes(data?.category)}
                                onChange={(e: any) => hendlClicktopics(e)}
                              />
                              <i className="awe-icon awe-icon-check"></i>
                              {data?.category}
                            </label>
                          </li>
                        );
                      })}
                  </ul>
                </div>
                {/* <!-- END / WIDGET --> */}

                <div className="widget sidebar-title ads-blog">
                  <img src="assets/img/ad-blog.png" className="scale" alt="" />
                </div>
                {/* <!-- WIDGET End -->

                            <!-- WIDGET --> */}
                {filtered && (
                  <div className="widget counsellor blogrecent shade2 ">
                    <h5 className="headgreenbg">recent </h5>
                    <ul className="bg-white">
                      {filtered &&
                        filtered?.map((data: any, i: any) => {
                          return (
                            i < 6 && (
                              <li key={i}>
                                <div
                                  className="resent_blog"
                                  style={{
                                    background: `url(${baseurl + data?.images
                                      })`,
                                  }}
                                ></div>
                                {/* <img src={baseurl+data?.images} alt="" /> */}
                                {/* <img src="assets/img/testi.png" alt="" /> */}

                                <p
                                  onClick={() => {
                                    history.push(
                                      "/blog/" + data?.slug,
                                      {
                                        blog_id: data?.slug,
                                      }
                                    );
                                  }}
                                >
                                  <a>
                                    {" "}
                                    {data?.heading &&
                                      data?.heading}
                                  </a>
                                </p>
                              </li>
                            )
                          );
                        })}
                    </ul>
                  </div>
                )}

                {/* <!-- END / WIDGET --> */}
              </div>
            </div>
            <div className="col-lg-9">
              <div className="filter-page__content">
                <div className="filter-item-wrapper">
                  {/* <!-- ITEM --> */}
                  <div className="blog-list pb-0">
                    <div className="row">
                      <div className="col-12">
                        <div className="headingmains text-center">
                          <h4 className="titlewithline">
                            The Latest Blog
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="40"
                                height="40"
                                viewBox="0 0 40 40"
                              >
                                <g id="checklist" transform="translate(0)">
                                  <g
                                    id="Group_4218"
                                    data-name="Group 4218"
                                    transform="translate(32.968 7.109)"
                                  >
                                    <g id="Group_4217" data-name="Group 4217">
                                      <path
                                        id="Path_6198"
                                        data-name="Path 6198"
                                        d="M428.66,95.84l-2.1-4.193a1.172,1.172,0,0,0-2.1,0l-2.1,4.193A3.533,3.533,0,0,0,422,97.413v20.54a3.516,3.516,0,0,0,7.031,0V97.413A3.534,3.534,0,0,0,428.66,95.84Zm-1.973,22.113a1.172,1.172,0,0,1-2.344,0v-1.172h2.344Zm0-3.516h-2.344V100.375h2.344Zm0-16.406h-2.344v-.619a1.178,1.178,0,0,1,.124-.524l1.048-2.1,1.048,2.1a1.179,1.179,0,0,1,.124.524Z"
                                        transform="translate(-422 -91)"
                                        fill="#fbb415"
                                      />
                                    </g>
                                  </g>
                                  <g
                                    id="Group_4220"
                                    data-name="Group 4220"
                                    transform="translate(0 0)"
                                  >
                                    <g id="Group_4219" data-name="Group 4219">
                                      <path
                                        id="Path_6199"
                                        data-name="Path 6199"
                                        d="M26.953,4.688H23.236a3.521,3.521,0,0,0-3.314-2.344H18.549a3.516,3.516,0,0,0-6.63,0H10.547A3.521,3.521,0,0,0,7.233,4.688H3.516A3.52,3.52,0,0,0,0,8.2V36.484A3.52,3.52,0,0,0,3.516,40H26.953a3.52,3.52,0,0,0,3.516-3.516V8.2A3.52,3.52,0,0,0,26.953,4.688ZM9.375,5.859a1.173,1.173,0,0,1,1.172-1.172h2.344a1.172,1.172,0,0,0,1.172-1.172,1.172,1.172,0,0,1,2.344,0,1.172,1.172,0,0,0,1.172,1.172h2.344a1.173,1.173,0,0,1,1.172,1.172V7.031H9.375Zm18.75,30.625a1.173,1.173,0,0,1-1.172,1.172H3.516a1.173,1.173,0,0,1-1.172-1.172V8.2A1.173,1.173,0,0,1,3.516,7.031H7.031V8.2A1.172,1.172,0,0,0,8.2,9.375H22.266A1.172,1.172,0,0,0,23.438,8.2V7.031h3.516A1.173,1.173,0,0,1,28.125,8.2Z"
                                        transform="translate(0)"
                                        fill="#fbb415"
                                      />
                                    </g>
                                  </g>
                                  <g
                                    id="Group_4222"
                                    data-name="Group 4222"
                                    transform="translate(4.687 11.797)"
                                  >
                                    <g id="Group_4221" data-name="Group 4221">
                                      <path
                                        id="Path_6200"
                                        data-name="Path 6200"
                                        d="M69.031,151.343a1.172,1.172,0,0,0-1.657,0L63.515,155.2,62,153.686a1.172,1.172,0,1,0-1.657,1.657l2.344,2.344a1.172,1.172,0,0,0,1.657,0L69.031,153A1.172,1.172,0,0,0,69.031,151.343Z"
                                        transform="translate(-60 -150.999)"
                                        fill="#047475"
                                      />
                                    </g>
                                  </g>
                                  <g
                                    id="Group_4224"
                                    data-name="Group 4224"
                                    transform="translate(4.687 20)"
                                  >
                                    <g id="Group_4223" data-name="Group 4223">
                                      <path
                                        id="Path_6201"
                                        data-name="Path 6201"
                                        d="M69.031,256.343a1.172,1.172,0,0,0-1.657,0L63.515,260.2,62,258.686a1.172,1.172,0,1,0-1.657,1.657l2.344,2.344a1.172,1.172,0,0,0,1.657,0L69.031,258A1.172,1.172,0,0,0,69.031,256.343Z"
                                        transform="translate(-60 -255.999)"
                                        fill="#047475"
                                      />
                                    </g>
                                  </g>
                                  <g
                                    id="Group_4226"
                                    data-name="Group 4226"
                                    transform="translate(4.687 28.203)"
                                  >
                                    <g id="Group_4225" data-name="Group 4225">
                                      <path
                                        id="Path_6202"
                                        data-name="Path 6202"
                                        d="M69.031,361.343a1.172,1.172,0,0,0-1.657,0L63.515,365.2,62,363.686a1.172,1.172,0,1,0-1.657,1.657l2.344,2.344a1.172,1.172,0,0,0,1.657,0L69.031,363A1.172,1.172,0,0,0,69.031,361.343Z"
                                        transform="translate(-60 -361)"
                                        fill="#047475"
                                      />
                                    </g>
                                  </g>
                                  <g
                                    id="Group_4228"
                                    data-name="Group 4228"
                                    transform="translate(16.406 30.547)"
                                  >
                                    <g id="Group_4227" data-name="Group 4227">
                                      <path
                                        id="Path_6203"
                                        data-name="Path 6203"
                                        d="M218.2,391h-7.031a1.172,1.172,0,0,0,0,2.344H218.2a1.172,1.172,0,0,0,0-2.344Z"
                                        transform="translate(-210 -391)"
                                        fill="#047475"
                                      />
                                    </g>
                                  </g>
                                  <g
                                    id="Group_4230"
                                    data-name="Group 4230"
                                    transform="translate(16.406 14.141)"
                                  >
                                    <g id="Group_4229" data-name="Group 4229">
                                      <path
                                        id="Path_6204"
                                        data-name="Path 6204"
                                        d="M218.2,181h-7.031a1.172,1.172,0,0,0,0,2.344H218.2a1.172,1.172,0,0,0,0-2.344Z"
                                        transform="translate(-210 -181)"
                                        fill="#047475"
                                      />
                                    </g>
                                  </g>
                                  <g
                                    id="Group_4232"
                                    data-name="Group 4232"
                                    transform="translate(16.406 22.344)"
                                  >
                                    <g id="Group_4231" data-name="Group 4231">
                                      <path
                                        id="Path_6205"
                                        data-name="Path 6205"
                                        d="M218.2,286h-7.031a1.172,1.172,0,0,0,0,2.344H218.2a1.172,1.172,0,0,0,0-2.344Z"
                                        transform="translate(-210 -286)"
                                        fill="#047475"
                                      />
                                    </g>
                                  </g>
                                </g>
                              </svg>
                            </span>
                          </h4>
                        </div>
                        {
                          filterBlog?.length == 0 ? <LoadingScreen /> : ""
                        }
                        <div className="all-center sub-section pb-0">
                          {filterBlog?.length == 0 ? (
                            <div className="nodataFound">
                              <img src="/assets/img/noDataFound.jpeg" alt="" />
                              {/* <h4>No blog found!</h4> */}
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="row">
                            {filterBlog &&
                              filterBlog?.map((data: any, i: any) => {
                                const result: any = data?.BlogLikes?.some(
                                  (obj: any) =>
                                    obj?.student_id == user?.id && user?.id
                                );
                                let date = dateFormat(
                                  data?.created_at,
                                  "mmm dS, yyyy"
                                );
                                return (
                                  <div className="col-md-4" key={i}>
                                    <div className="blog-item">
                                      <figure
                                        className="feat-text1 mb-0 blog_list"
                                        onClick={() => {
                                          history.push(
                                            "/blog/" + data?.slug,
                                            {
                                              blog_id: data?.id,
                                            }
                                          );
                                        }}
                                        style={{
                                          background: `url(${baseurl + data?.images
                                            })`,
                                        }}
                                      >
                                        <div className="divroted"></div>
                                        {/* <img
                                            onClick={() => {
                                              history.push(
                                                "/blog?id=" + data?.id,
                                                {
                                                  blog_id: data?.id,
                                                }
                                              );
                                            }}
                                            className="scale"
                                            src={baseurl+data?.feature_image}
                                          /> */}
                                      </figure>
                                      <figcaption className="content-box counsellorName">
                                        <h6
                                          onClick={() => {
                                            history.push(
                                              "/blog/" + data?.slug,
                                              {
                                                blog_id: data?.id,
                                              }
                                            );
                                          }}
                                        >
                                          {data?.heading && data?.heading}{" "}
                                          {/* Goodbye , Lockdown: U.K.*/}{" "}
                                        </h6>
                                        <p className="yellow pb-1">
                                          <small>
                                            {data?.BlogCategory?.category &&
                                              data?.BlogCategory?.category}
                                            {/* News */}
                                          </small>
                                        </p>
                                        <p>
                                          <small>
                                            {" "}
                                            By {data?.auther &&
                                              data?.auther}{" "}
                                            on {date}{" "}
                                          </small>
                                        </p>
                                        <hr />
                                        <p>
                                          {/* {data?.short_details} */}

                                          { }
                                          {data?.short_details &&
                                            data?.short_details?.length > 120
                                            ? `${data?.short_details.substring(
                                              0,
                                              120
                                            )}...`
                                            : data?.short_details}
                                          {/* The terrific restrictions of the pandemic
                                    brought almost all the countries amidst a
                                    crisis. Just not hitting the finances, but
                                    also men ...{" "} */}
                                        </p>

                                        <ul className="iconscomments">
                                          <li>
                                            <a
                                              onClick={(e) => {
                                                hendleLike(true, data?.id);
                                              }}
                                            >
                                              {" "}
                                              <i
                                                className={
                                                  result == true
                                                    ? "fa fa-thumbs-o-up active"
                                                    : "fa fa-thumbs-o-up"
                                                }
                                                aria-hidden="false"
                                              ></i>{" "}
                                              {/* 400 */}
                                              {data?.like_count &&
                                                data?.like_count}
                                            </a>
                                          </li>
                                          <li>
                                            {/* <a
                                                onClick={(e) => {
                                                  hendlecomment(true);
                                                }}
                                              > */}{" "}
                                            <i
                                              className="fa fa-comment yellow"
                                              aria-hidden="true"
                                            ></i>{" "}
                                            {/* 300 */}
                                            {data?.comment_count == null
                                              ? "0"
                                              : data?.comment_count}
                                            {/* </a> */}
                                          </li>
                                          <li>
                                            <i
                                              className="fa fa-eye yellow"
                                              aria-hidden="true"
                                            ></i>{" "}
                                            {data?.view_count == null
                                              ? "0"
                                              : data?.view_count}
                                            {/* 450 */}
                                          </li>
                                        </ul>
                                        {/* <Link
                                          to={"/blog"}
                                          className="btnarrow"
                                        >
                                          <i
                                            className="fa fa-arrow-right"
                                            aria-hidden="true"
                                          ></i>
                                        </Link> */}
                                        <a
                                          onClick={() => {
                                            history.push(
                                              "/blog/" + data?.slug,
                                              {
                                                blog_id: data?.id,
                                              }
                                            );
                                          }}
                                          className="btnarrow"
                                        >
                                          <i
                                            className="fa fa-arrow-right"
                                            aria-hidden="true"
                                          ></i>
                                        </a>
                                        <div className="clearfix"></div>
                                      </figcaption>
                                    </div>
                                  </div>
                                )
                              })}


                            {filterBlog?.length > 0 ? (
                              <div className="col-12">
                                <AdSlider />
                              </div>
                            ) : (
                              " "
                            )}

                          </div>
                          {filterBlog?.length > 0 ? <div className="all-center my-2">
                            <a
                              onClick={(e) => { hendleLodemore(e) }}
                              className="view-more readbtn"
                            >
                              Load More
                            </a>
                          </div> : ""}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- END / ITEM --> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Blog;
